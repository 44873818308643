<template>
  <div class="top_message main_padding-t">

    <!-- <div class="top_text w1400">
      <p>
        日商麒麟技術是1988年以麒麟集團的酒類、飲料工廠擴增設備為基礎而設立。
        隨著食品以及醫藥領域的擴大，
        日商麒麟技術於工廠建設、工廠擴建服務超過30年的經驗，實現顧客的理想。
      </p>
    </div> -->

    <div class="info_text w1400">
      <h2 class="h1 border_title txt-right main_padding-b">
        <span class="txt-bold">KIRIN</span>
        <span>&nbsp;Engineering</span>
      </h2>

      <div class="textbox">
        <div class="left">
          <h5 class="sub_title txt-bold">社長的話</h5>
          <p>

            日商麒麟技術是1988年自麒麟啤酒的工程部門獨立出來的公司。我們繼承了麒麟集團以
            顧客為本、追求品質的精神，透過飲料、食品、藥品工廠的各種新建及擴建工程，實現了
            許多客戶的製造願景，並為食品和健康的問題解決做出了貢獻。
            <br><br>
            即使在難以預測未來的現代當中，我們的堅持仍不改變。我們深入了解客戶，建立與所有
            相關人士的信任關係，並重視持續學習與進步的心態。我們相信這種堅持將帶來超越客戶
            期望的工程成果，進一步實現客戶的願景。
            <br><br>
            未來，我們將繼續努力成為值得信賴的合作夥伴，與客戶共同創造新的價值，致力於解決
            與食品和健康相關的各種社會問題。

          </p>
          <img src="@/assets/img/about/top_message/02_img.jpg" alt="">
        </div>
        <!-- <div class="right">
          <h6>01.企業理念</h6>
          <p>
            本公司與顧客的感動連結，提供領先的工程技術，貢獻於「食品與健康」文化的繁榮以及人們的幸福。
          </p>
        </div> -->
      </div>

    </div>

    <div class="five_points main_margin-t">

      <div class="contorl w1400 txt-white">
        <h6>企業理念</h6>
        <!-- <div class="contorlbox">
          <div class="left"></div>
          <div class="right"></div>
        </div> -->
      </div>

      <div class="text_block txt-white">
        <div class="in">
          <div class="in-wording">
            <div class="">
              <p>深入地了解顧客，與所有相關人士建立信任關係，</p>
              <p>堅持不懈地學習，提供“超越期望的工程技術”，實現顧客的願景。</p>
              <p>同時，成為受到顧客信任的夥伴，在從食品到醫療的領域中，創造一個人們可以享受飲食並身心健康的“充滿喜悅的社會”。</p>
            </div>
            <div class="in-wording__block">
              <p class="txt-superbold">Misson What we do：我們要做什麼？</p>
              <p>超越期望的工程技術，實現顧客的願景</p>
            </div>
            <div class="in-wording__block">
              <p class="txt-superbold">Vision What we will see：我們要創造怎樣的景象？</p>
              <p>成為在食品到醫療的領域中受到顧客信任的夥伴，</p>
              <p>創造一個充滿喜悅的社會。</p>
            </div>
            <div class="in-wording__block">
              <p class="txt-superbold">Value Who we are：我們要追求什麼？</p>
              <p>◆理解顧客</p>
              <p>◆建立信任關係</p>
              <p>◆不斷學習</p>
            </div>
          </div>
          <!-- <h2
            class="h1 txt-up"
            v-html="topMessageSwiper[topMessageSwiperNowIndex].title">
          </h2> -->
          <!-- <div class="under-in-wording">
            <h5 class="sub_title sub_title-white">
              {{ topMessageSwiper[topMessageSwiperNowIndex].subTitle }}
            </h5>
            <p>
              {{ topMessageSwiper[topMessageSwiperNowIndex].text }}
            </p>
          </div> -->
        </div>
      </div>

      <div class="pic_block">
        <figure :style="{
                backgroundImage: `url(${topMessageSwiper[0].pic})`
              }">
        </figure>
        <!-- <swiper
          :options="swiperOption"
          :ref="'five_point_swiper'"
          @transitionStart="fivePointOnChange">
            <swiper-slide
              v-for="(item, i) in topMessageSwiper"
              :key="`points_${i}`">
                <figure
                  :style="{
                    backgroundImage: `url(${item.pic})`
                  }">
                </figure>
            </swiper-slide>
        </swiper> -->
      </div>

    </div>

  </div>
</template>

<script>
import { topMessageSwiper } from '@/utils/aboutPageData';

export default {
  name: 'TopMessage',
  components: {
  },
  data() {
    return {
      topMessageSwiper,
      topMessageSwiperNowIndex: 0,
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: false,
        navigation: {
          nextEl: '.five_points .contorlbox .right',
          prevEl: '.five_points .contorlbox .left',
        },
      },
    };
  },
  computed: {
    fivePointSwiper() {
      return this.$refs.five_point_swiper.swiper;
    },
  },
  methods: {
    fivePointOnChange() {
      this.topMessageSwiperNowIndex = this.fivePointSwiper.realIndex;
      console.log(this.fivePointSwiper.realIndex);
    },
  },
};
</script>
