import aboutBgPicTopMessage from '../assets/img/about/About_1.jpg';
import aboutBgPicHistory from '../assets/img/about/About_2.jpg';
import aboutBgPicCompanyProfile from '../assets/img/about/About_3.jpg';
// import aboutBgPicOperationPolicy from '../assets/img/about/About_4.jpg';

import topMessageSwiperPic01 from '../assets/img/about/top_message/03_img1.jpg';
import topMessageSwiperPic02 from '../assets/img/about/top_message/03_img2.jpg';
import topMessageSwiperPic03 from '../assets/img/about/top_message/03_img3.jpg';
import topMessageSwiperPic04 from '../assets/img/about/top_message/03_img4.jpg';
import topMessageSwiperPic05 from '../assets/img/about/top_message/03_img5.jpg';

export const aboutPageData = [
  {
    routerName: 'TopMessage',
    pageName: '經營理念',
    pageNameEn: 'Top Message',
    mainBg: aboutBgPicTopMessage,
    titleWord: [
      {
        word: 'TOP',
        ifBold: false,
      },
      {
        word: 'MESSAGE',
        ifBold: true,
      },
    ],
    bottomBgColor: '#f8f8f8',
  },
  {
    routerName: 'History',
    pageName: '沿革',
    pageNameEn: 'History',
    mainBg: aboutBgPicHistory,
    titleWord: [
      {
        word: '',
        ifBold: false,
      },
      {
        word: 'History',
        ifBold: true,
      },
    ],
    bottomBgColor: '#f8f8f8',
  },
  {
    routerName: 'CompanyProfile',
    pageName: '公司簡介',
    pageNameEn: 'Company Profile',
    mainBg: aboutBgPicCompanyProfile,
    titleWord: [
      {
        word: 'Company',
        ifBold: false,
      },
      {
        word: 'Profile',
        ifBold: true,
      },
    ],
    bottomBgColor: '#384753',
  },
  // {
  //   routerName: 'OperationPolicy',
  //   pageName: '經營方針',
  //   pageNameEn: 'Policy',
  //   mainBg: aboutBgPicOperationPolicy,
  //   titleWord: [
  //     {
  //       word: null,
  //       ifBold: false,
  //     },
  //     {
  //       word: 'Policy',
  //       ifBold: true,
  //     },
  //   ],
  //   bottomBgColor: '#ffffff',
  // },
];

export const topMessageSwiper = [
  {
    pic: topMessageSwiperPic01,
    title: `
      <span class="txt-bold">Customer's</span>
      <span> Perspective</span>
    `,
    subTitle: '顧客的角度',
    text: '以顧客的角度為發想的原點，持續滿足顧客的需求。',
  },
  {
    pic: topMessageSwiperPic02,
    title: `
      <span class="txt-bold">TECHNICAL</span>
      <span> PURSUIT</span>
    `,
    subTitle: '技術的追求',
    text: '身為專業技術工程公司持續追求獨一無二的技術。',
  },
  {
    pic: topMessageSwiperPic03,
    title: `
      <span class="txt-bold">Creativity</span>
      <span> effort</span>
    `,
    subTitle: '創意與努力',
    text: '秉持著創意及努力，持續因應新事物及環境變化。',
  },
  {
    pic: topMessageSwiperPic04,
    title: `
      <span class="txt-bold">Enthusiasm</span>
      <span> Sincerity</span>
    `,
    subTitle: '熱情與誠意',
    text: '為了能夠珍惜每一份「感謝」，而隨時保有熱情以及誠意的心來行動。',
  },
  {
    pic: topMessageSwiperPic05,
    title: `
      <span class="txt-bold">partnership</span>
    `,
    subTitle: '夥伴關係',
    text: '建立與所有夥伴的信賴關係，與夥伴們分享感動。',
  },
];

export const historySwiper = [
  {
    year: 1988,
    history: [
      {
        date: '1988年3月',
        text: 'KIRIN ENGINEERING COMPANY LIMITED設立',
      },
      {
        date: '1988年5月',
        text: '因台灣公賣局生啤酒工程項目，日本麒麟啤酒公司獨資設立「台灣麒麟工程股份有限公司」',
      },
    ],
  },
  {
    year: 1993,
    history: [
      {
        date: '1993年',
        text: '開始販售「麒麟生啤酒」',
      },
      {
        date: '1993年',
        text: '組織調整：工程事業部、啤酒事業部',
      },
    ],
  },
  {
    year: 1995,
    history: [
      {
        date: '1995年',
        text: '開始販售「麒麟一番搾啤酒」',
      },
    ],
  },
  {
    year: 1997,
    history: [
      {
        date: '1997年',
        text: '公司名變更為「台灣麒麟啤酒股份有限公司」',
      },
    ],
  },
  {
    year: 2006,
    history: [
      {
        date: '2006年',
        text: '工程事業部轉移至 KIRIN ENGINEERING COMPANY LIMITED旗下，成立「日商麒麟技術股份有限公司台北分公司」',
      },
      {
        date: '2006年',
        text: '啤酒事業部接續「台灣麒麟啤酒股份有限公司」',
      },
    ],
  },
  {
    year: 2016,
    history: [
      {
        date: '2016年',
        text: '設立越南事業所',
      },
    ],
  },
  {
    year: 2018,
    history: [
      {
        date: '2018年',
        text: '設立緬甸支店',
      },
    ],
  },
  {
    year: 2019,
    history: [
      {
        date: '2019年1月',
        text: '「日商麒麟技術股份有限公司台北分公司」遷移至現今地址',
      },
    ],
  },
];

export const operationPolicyProcess = [
  {
    name: 'consult',
    textTitle: '諮詢',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'case',
    textTitle: '接案',
    textInfo: '將「是否滿足顧客需求?」時時掛記在心，製作出涵蓋專案目標及品質計畫的專案執行文件，再聚集相關人員，一同參與「專案啟動會議」，並審查專案內容。',
  },
  {
    name: 'design',
    textTitle: '設計',
    textInfo: '與專家一同執行「設計審查」，於安排施工前，審查設計上有無設計缺陷。',
  },
  {
    name: 'conduct',
    textTitle: '施工',
    textInfo: '將施工相關的品質計畫(檢查清單等等)紀錄於專案執行文件，聚集相關人員於「現場開工會議」互相確認施工的管理項目。由專家於工地現場進行「施工審查」確認施工層面上有無缺陷。',
  },
  {
    name: 'delivery',
    textTitle: '點交',
    textInfo: '專案經理確認檢查清單中的內容是否全部完成，向顧客提出驗收申請。',
  },
  {
    name: 'complete',
    textTitle: '完成專案',
    textInfo: `
      <div class="activity_list">
        <p><span> 統整專案執行文件(品質計畫書)的目標及執行結果，並運用於未來的專案營運管理。</span></p>
        <p><span> 顧客滿意度是營業部人員詢問顧客對專案的評價、問題解決或客訴等資料彙整而成。</span></p>
        <p><span> 將專案實績透過公司內部技術資產及定期報告會議進行分享。</span></p>
      </div>`,
  },
];
